import * as React from "react";
import * as style from "./styles/trainingProgram.module.scss";
import { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import "components/DinoGame/dinoGame.css";
import DinoGameResources from "components/DinoGame/DinoGameResources";
import { dinoGame } from "components/DinoGame/dinoGame";
import BoundedSection from "components/BoundedSection/";
import Cta from "components/Cta";

import InsertEmoticonTwoToneIcon from "@mui/icons-material/InsertEmoticonTwoTone";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";

// TODO add extended Link component
const ExternalLink = ({ link, children }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      data-cursor-size="48px"
      data-cursor-color="#ffffff20"
    >
      {children}
    </a>
  );
};

const DinoGame = () => {
  const startDiv = useRef(null);
  const endDiv = useRef(null);
  useEffect(() => {
    const appendDinoScript = () => {
      let dinoScriptContainer = document.createElement("script");
      dinoScriptContainer.appendChild(document.createTextNode(dinoGame));
      startDiv.current.appendChild(dinoScriptContainer);
    };
    const appendRunnerScript = () => {
      let runnerScriptContainer = document.createElement("script");
      runnerScriptContainer.appendChild(
        document.createTextNode(`new Runner('.interstitial-wrapper');`)
      );
      endDiv.current.appendChild(runnerScriptContainer);
    };
    appendDinoScript();
    appendRunnerScript();
    const disableScroll = (event) => {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", disableScroll);
    return () => {
      window.removeEventListener("keydown", disableScroll);
    };
  }, []);

  return (
    <div ref={startDiv}>
      <div id="main-frame-error" className="interstitial-wrapper">
        <DinoGameResources />
        <div ref={endDiv}></div>
      </div>
    </div>
  );
};

const Dino = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  return (
    <div className={style.DinoWrapper}>
      {!modalOpen && (
        <svg
          className={style.Dino}
          className={`DinoTrigger ${style.Dino}`}
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 267.5107422 179.583252"
          version="1.1"
          viewBox="0 0 267.511 179.583"
          xmlSpace="preserve"
          onClick={() => (modalOpen ? close() : open())}
        >
          <g>
            <path
              d="M149.397 145.585H267.51099999999997V149.847H149.397z"
              className={style.Dino_floor}
            />
            <path
              d="M0 145.585H66.608V149.847H0z"
              className={style.Dino_floor}
            />
            <path
              d="M25.666 162.489H41.419V166.751H25.666z"
              className={style.Dino_floor}
            />
            <path
              d="M0.699 175.322H16.452V179.584H0.699z"
              className={style.Dino_floor}
            />
            <path
              d="M200.577 175.322H216.32999999999998V179.584H200.577z"
              className={style.Dino_floor}
            />
            <path
              d="M230.184 158.227H241.618V162.489H230.184z"
              className={style.Dino_floor}
            />
            <path
              d="M58.525 175.322H66.608V179.584H58.525z"
              className={style.Dino_floor}
            />
            <path
              d="M171.024 171.06H175.066V175.322H171.024z"
              className={style.Dino_floor}
            />
            <path
              d="M132.42 138.187h9.555v-8.859h8.537v-12.08h8.011V87.596h7.87v8.524h9.261V79.073h-17.13v-15.96h33.153V54.59h15.739V8.523h-8.596V0h-65.31v8.523h-8.14v54.59h-9.02v8.39h-11.89v8.054H91.675v8.859h-8.537v7.704H66.608v-8.524h-8.083v-8.523h-9.413v-15.96H41.42v50.188h8.602v7.647h8.504v8.38h8.083v8.523h11.434v2.27h1.457v2.328h1.764v1.933h2.55v26.678h-.193v8.523h17.106v-8.523h-9.219v-8.571h8.182v-8.523h8.164v-8.38h8.497v8.38h8.376v24.494h.232v1.123h17.106v-8.523h-9.644v-32.873zm9.555-126.362h8.537v8.537h-8.537v-8.537z"
              className={style.Dino_body}
            />
          </g>
          <g className={style.Dino_glasses}>
            <path d="M106.927 11.305v3.624h27.81v3.624h3.676v3.624h3.596v3.618h3.687v3.624h21.896v-3.624h3.628v-3.618h3.823v-3.624h7.43v3.624h3.62v3.618h3.562v3.624h21.896v-3.624h3.762v-3.618h3.824v-3.624h3.58v-7.248h-115.79zm42.344 14.49v-3.618h3.619v3.618h-3.62zm10.905 0h-3.667v-3.618h3.667v3.618zm-3.667-3.624h-3.619v-3.618h-3.62v3.618h-3.619v-3.618h-3.619V14.93h3.62v3.624h3.619V14.93h3.619v3.624h3.62v3.618zm47.73 3.618l-3.629.006v-3.618l3.63-.006v3.618zm-3.629-3.618h-3.619v3.624h-3.626v-3.618h3.619v-3.624h3.626v3.618zm-3.66-3.618h-3.568l-.017 3.618h-3.603v-3.618h-3.619V14.93h3.602v3.624h3.62l.017-3.624h3.569v3.624z" />
            <path d="M99.689 14.929H106.928V18.553H99.689z" />
            <path
              d="M142.032 14.929H145.651V18.553H142.032z"
              className={style.Dino_shineGlasses}
            />
            <path
              d="M149.270813 22.1710815L149.270813 18.5530396 145.6515503 18.5530396 145.6515503 22.1771851 149.270813 22.1771851 149.270813 25.7952271 152.8900146 25.7952271 152.8900146 22.1710815z"
              className={style.Dino_shineGlasses}
            />
            <path
              d="M149.271 14.929H152.89V18.553H149.271z"
              className={style.Dino_shineGlasses}
            />
            <path
              d="M156.5093079 22.1710815L156.5093079 18.5530396 152.8900452 18.5530396 152.8900452 22.1771851 156.5093079 22.1771851 156.5093079 25.7952271 160.1285095 25.7952271 160.1285095 22.1710815z"
              className={style.Dino_shineGlasses}
            />
            <g>
              <path
                d="M186.143 14.929H189.762V18.553H186.143z"
                className={style.Dino_shineGlasses}
              />
              <path
                d="M193.3815613 22.1710815L193.3815613 18.5530396 189.7622986 18.5530396 189.7622986 22.1771851 193.3815613 22.1771851 193.3815613 25.7952271 197.0007629 25.7952271 197.0007629 22.1710815z"
                className={style.Dino_shineGlasses}
              />
            </g>
            <g>
              <path
                d="M193.382 14.929H197.001V18.553H193.382z"
                className={style.Dino_shineGlasses}
              />
              <path
                d="M200.6200562 22.1710815L200.6200562 18.5530396 197.0007935 18.5530396 197.0007935 22.1771851 200.6200562 22.1771851 200.6200562 25.7952271 204.2392578 25.7952271 204.2392578 22.1710815z"
                className={style.Dino_shineGlasses}
              />
            </g>
          </g>
        </svg>
      )}
      {modalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <DinoGame />
        </motion.div>
      )}
    </div>
  );
};

const TrainingProgramPage = ({ data }) => {
  return (
    <PageWrapper
      seoTitle="Programa de formación de Trileuco"
      seoDescription="Comienza tu carrera profesional con nosotros"
    >
      <HeaderSection
        preTitle="Programa de formación de Trileuco"
        title={
          <>
            Comienza tu carrera profesional <br />
            con nosotros
          </>
        }
        description={<Dino />}
        disableOrbitBalls
      />
      <BoundedSection className={style.Info}>
        <h2>¿Has acabado tu formación o estás a punto de hacerlo?</h2>
        <p>
          Seguro que tienes muchas dudas, ¿verdad?{" "}
          <em>
            ¿Seré capaz de trabajar en una empresa? ¿Me integraré bien en el
            equipo? ¿Tendré el apoyo de mis compañeros para poder progresar? ¿Y
            si aún no estoy preparado/a para empezar a trabajar?
          </em>
        </p>
        <p>
          Tranqui, no eres la única persona que se ha hecho estas preguntas. En
          Trileuco, hemos pasado por lo mismo que tú y sabemos que es normal
          sentir un poco de miedo antes de dar el salto al mundo laboral. Pero,
          ¡te aseguramos que es el momento de empezar! Te animamos a dar el
          salto para que te conviertas en un Desarrollador de Software
          profesional.
        </p>
        <p>
          Para lograrlo, hemos creado un programa de formación en el que te
          ofrecemos <strong>3 meses de prácticas remuneradas</strong>. Formarás
          parte de un <strong>proyecto interno real</strong> y, si te lo curras,
          tendrás la oportunidad de{" "}
          <strong>formar parte de nuestra plantilla</strong>.
        </p>
        <h2>¿Quiénes somos?</h2>
        <p>
          Somos una empresa de desarrollo de software en la que lo más
          importante es el equipo. Trabajamos para lograr una gran calidad en
          todo lo que hacemos y valoramos mucho tu actitud, tus ganas y tu
          esfuerzo.
        </p>
        <p>
          En Trileuco nos preocupamos por ti y queremos ayudarte a dar sentido a
          todo lo que has aprendido hasta ahora. En la universidad, te han dado
          muchas piezas interesantes y nosotros te ayudaremos a encajarlas. Te
          ayudaremos a interiorizar buenas prácticas de programación, ¿te suena
          el{" "}
          <ExternalLink link="https://www.youtube.com/watch?v=7EmboKQH8lM&t=683s">
            <em>Clean Code</em>
          </ExternalLink>
          ? Queremos que te acostumbres a trabajar empleando{" "}
          <ExternalLink link="https://www.youtube.com/watch?v=9TycLR0TqFA">
            <em>SCRUM</em>
          </ExternalLink>
          , comprendas lo que es el{" "}
          <ExternalLink link="https://www.youtube.com/watch?v=dH5aSQLXtKg">
            <em>Domain-Driven-Design</em>
          </ExternalLink>{" "}
          y llegues a usar{" "}
          <ExternalLink link="https://nvie.com/img/git-model@2x.png">
            <em>GIT</em>
          </ExternalLink>{" "}
          con facilidad y de forma colaborativa.
        </p>
        <p>
          En resumen, nos preocuparemos de que estés cómodo/a y que tengas
          siempre a gente dispuesta a ayudarte a crecer.
        </p>
      </BoundedSection>
      <BoundedSection className={style.Expectations}>
        <h2 className={style.Expectations_title}>¿Y qué necesitamos de ti?</h2>
        <div className={style.Expectations_grid}>
          <div>
            <InsertEmoticonTwoToneIcon />
            <p>Que te sientas con ganas, tu actitud es lo primero.</p>
          </div>
          <div>
            <QueryBuilderTwoToneIcon />
            <p>
              Que dispongas de 5 horas al día. Es la jornada que te recomendamos
              y que mejor se adapta al programa.
            </p>
          </div>
        </div>
      </BoundedSection>
      <Cta
        className={style.GetStarted}
        isSticky
        title="Inscríbete en nuestro programa"
        description="Pulsa el siguiente botón para acceder al formulario de registro. ¡Te esperamos!"
        btn={<SendTwoToneIcon />}
        btnLink="https://forms.triskel.io/index.php/519378"
        stickyTitle="Inscríbete aquí"
      />
    </PageWrapper>
  );
};

export default TrainingProgramPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
