// extracted by mini-css-extract-plugin
export var Dino = "trainingProgram-module--Dino--E2Q7Q";
export var DinoWrapper = "trainingProgram-module--DinoWrapper--ihJNU";
export var Dino_body = "trainingProgram-module--Dino_body--xeR7Q";
export var Dino_floor = "trainingProgram-module--Dino_floor--3aklI";
export var Dino_glasses = "trainingProgram-module--Dino_glasses---vzg6";
export var Dino_shineGlasses = "trainingProgram-module--Dino_shineGlasses--cVoy6";
export var Expectations = "trainingProgram-module--Expectations--kpgY1";
export var Expectations_grid = "trainingProgram-module--Expectations_grid---14sX";
export var Expectations_title = "trainingProgram-module--Expectations_title--BUipu";
export var GetStarted = "trainingProgram-module--GetStarted--U6khO";
export var Info = "trainingProgram-module--Info--ldZxK";
export var bounceGlasses = "trainingProgram-module--bounceGlasses--uVHK3";