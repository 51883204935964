import * as React from "react";
import * as style from "./cta.module.scss";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import BoundedSection from "components/BoundedSection";

const buttonScale = {
  hover: {
    boxShadow: "0 0 0 12px #263546",
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
  tap: {
    boxShadow: "0 0 0 24px #263546",
    transition: {
      duration: 0.25,
      type: "spring",
    },
  },
};

const Cta = ({
  title,
  description,
  btn,
  btnLink,
  children,
  onBtnClick,
  isSticky,
  stickyTitle,
}) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const CtaBtn = ({ onBtnClick }) => (
    <motion.a
      className={style.Cta_btn}
      href={btnLink && btnLink}
      data-in-view={inView}
      layout
      variants={buttonScale}
      whileHover="hover"
      whileTap="tap"
      onClick={onBtnClick}
    >
      {btn}
    </motion.a>
  );
  return (
    <BoundedSection boundedClassName={style.Cta}>
      <h3 className={style.Cta_title}>{title}</h3>
      {description && (
        <p className={style.Cta_description} ref={ref}>
          {description}
        </p>
      )}
      <>
        {isSticky ? (
          <motion.div
            className={style.Cta_sticky}
            data-in-view={inView}
            layout
            transition={{
              type: "spring",
              delay: 0,
              duration: 1,
            }}
          >
            <a
              href={btnLink && btnLink}
              onClick={onBtnClick}
              className={style.Cta_stickyTitle}
              data-in-view={inView}
            >
              {stickyTitle}
            </a>
            <CtaBtn onBtnClick={onBtnClick} />
          </motion.div>
        ) : (
          <CtaBtn onBtnClick={onBtnClick} />
        )}
      </>
    </BoundedSection>
  );
};

export default Cta;
